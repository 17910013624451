import React from 'react';
import "./FooterComponent.scss";

function FooterComponent(props) {
    return (
        <footer className='footer'>
            <p>Bachelor Suite Skateboards</p>
        </footer>
    );
}

export default FooterComponent;